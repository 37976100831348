import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import icon1 from '../assets/img/icon1.png';
import icon2 from '../assets/img/icon2.png';
import icon3 from '../assets/img/icon3.png';
import icon4 from '../assets/img/icon4.png';
import icon5 from '../assets/img/icon5.png';
import icon6 from '../assets/img/icon6.png';

const Features = () => {
    return (
        <>
            <section className="featuresBG">
                <Container>
                    <div className='featuresText'>
                        <span className='slogan'>Lorem ipsum dolar sit amet</span>
                        <h2>Lorem ipsum dolar sit amet</h2>
                        <p>Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet Constructor  Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet Constructor </p>
                    </div>
                    <Row className='g-4'>
                        <Col lg={4} md={6}>
                            <div className='featureBox'>
                                <div className='featureImg'>
                                    <img src={icon1} alt="altText" />
                                </div>
                                <h5>Lorem ipsum dolar sit amet</h5>
                                <p>Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet  Lorem IpsumConstructor  Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet Constructor </p>
                            </div>    
                        </Col>
                        <Col lg={4} md={6}>
                            <div className='featureBox'>
                                <div className='featureImg'>
                                    <img src={icon2} alt="altText" />
                                </div>
                                <h5>Lorem ipsum dolar sit amet</h5>
                                <p>Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet  Lorem IpsumConstructor  Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet Constructor </p>
                            </div>    
                        </Col>
                        <Col lg={4} md={6}>
                            <div className='featureBox'>
                                <div className='featureImg'>
                                    <img src={icon3} alt="altText" />
                                </div>
                                <h5>Lorem ipsum dolar sit amet</h5>
                                <p>Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet  Lorem IpsumConstructor  Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet Constructor </p>
                            </div>    
                        </Col>
                        <Col lg={4} md={6}>
                            <div className='featureBox'>
                                <div className='featureImg'>
                                    <img src={icon4} alt="altText" />
                                </div>
                                <h5>Lorem ipsum dolar sit amet</h5>
                                <p>Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet  Lorem IpsumConstructor  Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet Constructor </p>
                            </div>    
                        </Col>
                        <Col lg={4} md={6}>
                            <div className='featureBox'>
                                <div className='featureImg'>
                                    <img src={icon5} alt="altText" />
                                </div>
                                <h5>Lorem ipsum dolar sit amet</h5>
                                <p>Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet  Lorem IpsumConstructor  Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet Constructor </p>
                            </div>    
                        </Col>
                        <Col lg={4} md={6}>
                            <div className='featureBox'>
                                <div className='featureImg'>
                                    <img src={icon6} alt="altText" />
                                </div>
                                <h5>Lorem ipsum dolar sit amet</h5>
                                <p>Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet  Lorem IpsumConstructor  Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet Constructor </p>
                            </div>    
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Features;