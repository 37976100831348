import React from 'react';
import { Col, Row, Container, Form, FormGroup, Label, Button, Input, FormLabel } from 'react-bootstrap';

const Footer = () => {
    return (
        <>
            <section className='footerBG'>
                <Container className='position-relative'>
                    <div className='newsletterMain'>
                        <div className='newsletterMain__inner'>
                            <div className='newsletterMain__title'>
                                <h2>Newsletters</h2>
                                <p>Subscribe to get monthly newsletter.</p>
                            </div>
                            <div className='newsletterMain__form'>
                                <Form>
                                    <FormGroup>
                                        <input id="exampleEmail"
                                            className='form-control'
                                            name="email"
                                            placeholder="Your Email Address"
                                            type="email" />
                                    </FormGroup>

                                    <Button className='theme-btn' type="submit">
                                        Submit
                                    </Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <div className="footerBottom">
                        <p>All rights reserved ( C )
                            LAVERSE</p>
                        <ul>
                            <li><a href='#'>Terms & Condition</a></li>
                            <li><a href='#'>Privacy Policy</a></li>
                            <li><a href='#'>Cookie Policy</a></li>
                        </ul>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Footer;