import React, {useEffect} from 'react';
import lottie from "lottie-web";
import { Col, Row, Container } from 'react-bootstrap';
import virtualMan from '../assets/img/virtualman.png';
import virtualManGif from '../animation/virtualevents.json';

const VirtualEvent = () => {

    useEffect(() => {
        lottie.loadAnimation({
          container: document.querySelector("#virtualgif"),
          animationData: virtualManGif,
          renderer: "svg", 
          loop: true, 
          autoplay: true, 
        });
      }, []);

    return (
        <>
            <section className='virtualEventBG' id="event">
                <Container>
                        <div className='vireventimg'>
                            <div id='virtualgif'></div>
                        </div>
                        <div class="featuresText"><span class="slogan">Lorem ipsum dolar sit amet</span><h2>Lorem ipsum dolar sit amet</h2><p>Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet Constructor  Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet Constructor </p></div>
                </Container>
            </section>
        </>
    )
}

export default VirtualEvent;