import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const LavarseModal = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
           
            <Modal.Body className='p-0'>
            <iframe width="560" height="315" style={{width: '100%'}} src="https://www.youtube.com/embed/D0UnqGm_miA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Modal.Body>
           
        </Modal>
    )
}
export default LavarseModal;