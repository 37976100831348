import React, {useEffect} from 'react';
import lottie from "lottie-web";
import { Col, Row, Container } from 'react-bootstrap';
import peoplesit from '../assets/img/peopleistting.png';
import coupleGif from '../assets/img/couple-f.gif';
import CoupleAnimation from '../animation/Couple.json';

const PeopleSitting = () => {
    useEffect(() => {
        lottie.loadAnimation({
          container: document.querySelector("#coupleGif"),
          animationData: CoupleAnimation,
          renderer: "svg", 
          loop: true, 
          autoplay: true, 
        });
      }, []);
    return (
        <>
            <section className="peoplesittingMain">
                <div id='coupleGif' />
                <Container>
                    <div className='peoplesitText'>
                        <div className='officegirlText pt-0'>
                            <span className='slogan text-white'>Lorem ipsum dolar sit amet</span>
                            <h2>Lorem ipsum dolar sit amet</h2>
                            <p>Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet Constructor  Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet Constructor </p>
                            <a href='#' className='theme-btn'>Get Started</a>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default PeopleSitting;