import React, { useEffect } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import illu1 from '../assets/img/illu1.png';
import illu2 from '../assets/img/illu2.png';
import illu3 from '../assets/img/illu3.png';
import illuLight from '../assets/img/illu-light.png';

const Counter = () => {


    return (
        <>
            <section className="counterBG">
                <Container>
                    <Row>
                        <Col lg={4}>
                            <div className='counterMain'>
                                <div className='counterImg'>
                                <div className='animategif'>
                                        <img src={illuLight} />
                                    </div>
                                    <img src={illu1} />
                                </div>
                                <div className='counterText'>
                                    <h4>19k</h4>
                                    <p>Users</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className='counterMain'>
                                <div className='counterImg'>
                                    <div className='animategif'>
                                        <img src={illuLight} />
                                    </div>
                                    <img src={illu2} />
                                </div>
                                <div className='counterText'>
                                    <h4>99+</h4>
                                    <p>Partners</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className='counterMain'>
                                <div className='counterImg'>
                                    <div className='animategif'>
                                        <img src={illuLight} />
                                    </div>
                                    <img src={illu3} />
                                </div>
                                <div className='counterText'>
                                    <h4>81+</h4>
                                    <p>Earners</p>
                                </div>
                            </div>
                        </Col>


                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Counter;