import React, {useEffect} from 'react';
import lottie from "lottie-web";
import { Col, Row, Container } from 'react-bootstrap';
import playcollectionObj from '../assets/img/playing.png';
import playCollectionGif from '../animation/Airplane.json';

const PlayCollections = () => {
    useEffect(() => {
        lottie.loadAnimation({
          container: document.querySelector("#plyGif"),
          animationData: playCollectionGif,
          renderer: "svg", 
          loop: true, 
          autoplay: true, 
        });
      }, []);
    return (
        <>
            <section className="playCollectionBG" id="gamepad">
                <Container>
                        <Row className='align-items-center'>
                        <Col lg={6}>
                                <div className='playcollectionImg'>
                                    <div id='plyGif'></div>
                                </div>    
                            </Col>
                            <Col lg={6}>
                                <div className='playcollectionText'>
                                    <span className='slogan'>Lorem ipsum dolar sit amet</span> 
                                    <h2>Start Your own collection</h2> 
                                    <p>Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet Constructor  Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet Constructor </p>  
                                    <ul className='themeList'>
                                        <li>Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum</li>
                                        <li>Lorem Ipsum Dolor Sit Amet Constructor</li>
                                        <li>Lorem Ipsum Dolor Sit</li>
                                        <li>Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum</li>
                                    </ul>
                                    <hr className='customHr' />
                                </div>    
                            </Col>
                           
                        </Row>
                </Container>
            </section>
        </>
    )
}

export default PlayCollections;