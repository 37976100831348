import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logo1 from '../assets/img/logo1.png';
import logo2 from '../assets/img/logo2.png';
import logo3 from '../assets/img/logo3.png';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const Partners = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <FaChevronRight />,
        prevArrow: <FaChevronLeft />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };
    return (
        <>
            <section className='partnerBG'>
                <Container className='position-relative'>

                    <div className='text-center partnerH'>
                        <h2>Our Partners</h2>
                    </div>

                    <Slider {...settings}>
                        <div className='partnerSldde'>
                            <img src={logo1} alt="card team" />
                        </div>
                        <div className='partnerSldde'>
                            <img src={logo2} alt="card team" />
                        </div>
                        <div className='partnerSldde'>
                            <img src={logo3} alt="card team" />
                        </div>
                        <div className='partnerSldde'>
                            <img src={logo1} alt="card team" />
                        </div>
                        <div className='partnerSldde'>
                            <img src={logo2} alt="card team" />
                        </div>
                        <div className='partnerSldde'>
                            <img src={logo3} alt="card team" />
                        </div>
                    </Slider>
                </Container>
            </section>
        </>
    )
}

export default Partners;