import React, { useEffect, useState } from 'react';
import lottie from "lottie-web";
import { Col, Row, Container, Badge } from 'react-bootstrap';
import aboutImg from '../assets/img/aboutimg.png';
import pluBig from '../assets/img/aboutPlus.svg';
import plugDouble from '../assets/img/aboutdoubleplus.svg';
import aboutImgGif from '../animation/about.json';
import BottomRect from '../assets/img/staking_rect.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import Header from "../components/Header";
import ethLogo from '../assets/img/ethLogo.png';
import stakingLaaverse from '../assets/img/stakingLaaverse.png';
import {Sugar} from 'react-preloaders';
import Particles from "react-tsparticles";


const Staking = () => {

       const particlesInit = (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };


     

    const [Oshow, OsetShow] = useState(false);
    const [Tshow, TsetShow] = useState(false);
    const [Thshow, ThsetShow] = useState(false);
    const [fshow, fsetShow] = useState(false);
  
    return (

        <>

          
            <Header />
                       <section className="stakingBG">
                       <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        background: {
          color: {
            value: "#0d47a1",
          },
        },
        fpsLimit: 120,
        interactivity: {
          events: {
            onClick: {
              enable: true,
              mode: "push",
            },
            onHover: {
              enable: true,
              mode: "repulse",
            },
            resize: true,
          },
          modes: {
            bubble: {
              distance: 400,
              duration: 2,
              opacity: 0.8,
              size: 40,
            },
            push: {
              quantity: 4,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            value: "#ffffff",
          },
          links: {
            color: "#ffffff",
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          collisions: {
            enable: true,
          },
          move: {
            direction: "none",
            enable: true,
            outMode: "bounce",
            random: false,
            speed: 3,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
            },
            value: 50,
          },
          opacity: {
            value: 0.5,
          },
          shape: {
            type: "circle",
          },
          size: {
            random: true,
            value: 5,
          },
        },
        detectRetina: true,
      }}
    />
               <div className='dividerOpacity'></div>
                <Container>
                   
                        <Row className='align-items-top'>
                         
                            <Col lg={3} className="order-1 order-lg-0">
                            
                              <div className='stakingWrapBg'>
                                <div className='stakingText'>
                                    <div className="stakingColHead d-flex align-items-center justify-content-between">
                                        <div> 
                                            <img src={ethLogo} className="ethLogo"/> <img src={stakingLaaverse} className="stakingLaaverse"/>    
                                        </div>
                                        <h3>ETH</h3>
                                    </div>
                                        <div className="d-flex align-items-center justify-content-between spacing">
                                            <div>APR</div>
                                            <div>100%</div>                             
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between spacing">
                                            <div>Earn:</div>
                                            <div>Laavarse</div>                             
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between spacing">
                                            <div>Withdraw</div>
                                            <div className="badge badge-warning bg-primary text-white"> <FontAwesomeIcon icon={faLockOpen} /> Open </div>                             
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between spacing">
                                            <div className="EarnedHeading">Laavarse EARNED</div>
                                                                        
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between valueHravestLine">
                                            <div className="HarvestValue">0.000</div>
                                            <button className="HarvestBtn">Harvest</button>
                                                                        
                                        </div>

                                         <div className="d-flex align-items-center justify-content-between spacing">
                                            <div className="EarnedHeading">Laavarse-ETH STAKED</div>
                                                                        
                                        </div>
                                    <a href='#' className='theme-btn smallbtn connectWallet'>Connect Wallet</a>


                                    <div className="text-center mt-4">
                                    Details
                                    <button className="checkBtn" onClick={() => OsetShow(prev => !prev)}>Check  </button>
      {Oshow && <div> <div className="d-flex align-items-center justify-content-between expandable">
                                            <div>Total Laavarse-ETH Staked:</div>
                                            <div>140,719.84044</div>                             
                                        </div><div className="d-flex align-items-center justify-content-between expandable">
                                            <div>Total liquidity:</div>
                                            <div>$85,397.85</div>                             
                                        </div><div className="d-flex align-items-center justify-content-between expandable">
                                            <div>Deposit Fee:</div>
                                            <div>--</div>                             
                                        </div><div className="d-flex align-items-center justify-content-between expandable">
                                            <div></div>
                                            <div>140,719.84044</div>                             
                                        </div><div className="d-flex align-items-center justify-content-between expandable">
                                            <div></div>
                                            <div>140,719.84044</div>                             
                                        </div><div className="d-flex align-items-center justify-content-between expandable">
                                            <div></div>
                                            <div>140,719.84044</div>                             
                                        </div>
                                    </div>}
                                    </div>

                                    
                                </div>


                              </div>

                                                              
                            </Col>
                        <Col lg={3} className="order-1 order-lg-0">
                            
                              <div className='stakingWrapBg'>
                                <div className='stakingText'>
                                    <div className="stakingColHead d-flex align-items-center justify-content-between">
                                         <div> 
                                            <img src={ethLogo} className="ethLogo"/> <img src={stakingLaaverse} className="stakingLaaverse"/>    
                                        </div>
                                        <h3>ETH</h3>
                                    </div>
                                        <div className="d-flex align-items-center justify-content-between spacing">
                                            <div>APR</div>
                                            <div>100%</div>                             
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between spacing">
                                            <div>Earn:</div>
                                            <div>Laavarse</div>                             
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between spacing">
                                            <div>Withdraw</div>
                                            <div className="badge badge-warning bg-primary text-white"> <FontAwesomeIcon icon={faLock} /> 1 Day</div>                             
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between spacing">
                                            <div className="EarnedHeading">Laavarse EARNED</div>
                                                                        
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between valueHravestLine">
                                            <div className="HarvestValue">0.000</div>
                                            <button className="HarvestBtn">Harvest</button>
                                                                        
                                        </div>

                                         <div className="d-flex align-items-center justify-content-between spacing">
                                            <div className="EarnedHeading">Laavarse-ETH STAKED</div>
                                                                        
                                        </div>
                                    <a href='#' className='theme-btn smallbtn connectWallet'>Connect Wallet</a>


                                    <div className="text-center mt-4">
                                    Details
                                    <button className="checkBtn" onClick={() => TsetShow(prev => !prev)}>Check  </button>
      {Tshow && <div> <div className="d-flex align-items-center justify-content-between expandable">
                                            <div>Total Laavarse-ETH Staked:</div>
                                            <div>140,719.84044</div>                             
                                        </div><div className="d-flex align-items-center justify-content-between expandable">
                                            <div>Total liquidity:</div>
                                            <div>$85,397.85</div>                             
                                        </div><div className="d-flex align-items-center justify-content-between expandable">
                                            <div>Deposit Fee:</div>
                                            <div>--</div>                             
                                        </div><div className="d-flex align-items-center justify-content-between expandable">
                                            <div></div>
                                            <div>140,719.84044</div>                             
                                        </div><div className="d-flex align-items-center justify-content-between expandable">
                                            <div></div>
                                            <div>140,719.84044</div>                             
                                        </div><div className="d-flex align-items-center justify-content-between expandable">
                                            <div></div>
                                            <div>140,719.84044</div>                             
                                        </div>
                                    </div>}
                                    </div>

                                    
                                </div>


                              </div>

                                                              
                            </Col>

                            <Col lg={3} className="order-1 order-lg-0">
                            
                              <div className='stakingWrapBg'>
                                <div className='stakingText'>
                                    <div className="stakingColHead d-flex align-items-center justify-content-between">
                                         <div> 
                                            <img src={ethLogo} className="ethLogo"/> <img src={stakingLaaverse} className="stakingLaaverse"/>    
                                        </div>
                                        <h3>ETH</h3>
                                    </div>
                                        <div className="d-flex align-items-center justify-content-between spacing">
                                            <div>APR</div>
                                            <div>100%</div>                             
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between spacing">
                                            <div>Earn:</div>
                                            <div>Laavarse</div>                             
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between spacing">
                                            <div>Withdraw</div>
                                            <div className="badge badge-warning bg-primary text-white"><FontAwesomeIcon icon={faLock} /> 15 Days</div>                             
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between spacing">
                                            <div className="EarnedHeading">Laavarse EARNED</div>
                                                                        
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between valueHravestLine">
                                            <div className="HarvestValue">0.000</div>
                                            <button className="HarvestBtn">Harvest</button>
                                                                        
                                        </div>

                                         <div className="d-flex align-items-center justify-content-between spacing">
                                            <div className="EarnedHeading">Laavarse-ETH STAKED</div>
                                                                        
                                        </div>
                                    <a href='#' className='theme-btn smallbtn connectWallet'>Connect Wallet</a>


                                    <div className="text-center mt-4">
                                    Details
                                    <button className="checkBtn" onClick={() => ThsetShow(prev => !prev)}>Check  </button>
      {Thshow && <div> <div className="d-flex align-items-center justify-content-between expandable">
                                            <div>Total Laavarse-ETH Staked:</div>
                                            <div>140,719.84044</div>                             
                                        </div><div className="d-flex align-items-center justify-content-between expandable">
                                            <div>Total liquidity:</div>
                                            <div>$85,397.85</div>                             
                                        </div><div className="d-flex align-items-center justify-content-between expandable">
                                            <div>Deposit Fee:</div>
                                            <div>--</div>                             
                                        </div><div className="d-flex align-items-center justify-content-between expandable">
                                            <div></div>
                                            <div>140,719.84044</div>                             
                                        </div><div className="d-flex align-items-center justify-content-between expandable">
                                            <div></div>
                                            <div>140,719.84044</div>                             
                                        </div><div className="d-flex align-items-center justify-content-between expandable">
                                            <div></div>
                                            <div>140,719.84044</div>                             
                                        </div>
                                    </div>}
                                    </div>

                                    
                                </div>


                              </div>

                                                              
                            </Col>

                            <Col lg={3} className="order-1 order-lg-0">
                            
                              <div className='stakingWrapBg'>
                                <div className='stakingText'>
                                    <div className="stakingColHead d-flex align-items-center justify-content-between">
                                         <div> 
                                            <img src={ethLogo} className="ethLogo"/> <img src={stakingLaaverse} className="stakingLaaverse"/>    
                                        </div>
                                        <h3>ETH</h3>
                                    </div>
                                        <div className="d-flex align-items-center justify-content-between spacing">
                                            <div>APR</div>
                                            <div>100%</div>                             
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between spacing">
                                            <div>Earn:</div>
                                            <div>Laavarse</div>                             
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between spacing">
                                            <div>Withdraw</div>
                                            <div className="badge badge-warning bg-primary text-white"><FontAwesomeIcon icon={faLock} /> 60 Days</div>                             
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between spacing">
                                            <div className="EarnedHeading">Laavarse EARNED</div>
                                                                        
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between valueHravestLine">
                                            <div className="HarvestValue">0.000</div>
                                            <button className="HarvestBtn">Harvest</button>
                                                                        
                                        </div>

                                         <div className="d-flex align-items-center justify-content-between spacing">
                                            <div className="EarnedHeading">Laavarse-ETH STAKED</div>
                                                                        
                                        </div>
                                    <a href='#' className='theme-btn smallbtn connectWallet'>Connect Wallet</a>


                                    <div className="text-center mt-4">
                                    Details
                                    <button className="checkBtn" onClick={() => fsetShow(prev => !prev)}>Check  </button>
      {fshow && <div> <div className="d-flex align-items-center justify-content-between expandable">
                                            <div>Total Laavarse-ETH Staked:</div>
                                            <div>140,719.84044</div>                             
                                        </div><div className="d-flex align-items-center justify-content-between expandable">
                                            <div>Total liquidity:</div>
                                            <div>$85,397.85</div>                             
                                        </div><div className="d-flex align-items-center justify-content-between expandable">
                                            <div>Deposit Fee:</div>
                                            <div>--</div>                             
                                        </div><div className="d-flex align-items-center justify-content-between expandable">
                                            <div></div>
                                            <div>140,719.84044</div>                             
                                        </div><div className="d-flex align-items-center justify-content-between expandable">
                                            <div></div>
                                            <div>140,719.84044</div>                             
                                        </div><div className="d-flex align-items-center justify-content-between expandable">
                                            <div></div>
                                            <div>140,719.84044</div>                             
                                        </div>
                                    </div>}
                                    </div>

                                    
                                </div>


                              </div>

                                                              
                            </Col>
                        

                           

                             
                        </Row>
                 
                    
                </Container>
              
            </section>
        </>
    )
}

export default Staking;