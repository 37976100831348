import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import team1 from '../assets/img/team1.png';
import team2 from '../assets/img/team2.png';
import team3 from '../assets/img/team3.png';
import team4 from '../assets/img/team4.png';
import teamPlus from '../assets/img/teamplus.png';

const Team = () => {
    return (
        <>
            <section className='teamMainBG'>
                <Container className='position-relative'>

                    <div className='teamHeader'>
                        <span className='slogan'>Our Timeline</span>
                        <h2>Our Team</h2>
                    </div>
                    <div className='themeObject teamplusTop'> <img src={teamPlus} alt="team" /></div>
                    <div className='themeObject teamplusBottom'> <img src={teamPlus} alt="team" /></div>
                    <Row className='align-items-end'>
                        <Col lg={3} md={6}>
                            <div className='teamMain'>
                                <div className='teamMain__frame'>
                                    <div className='teamMain__img'>
                                        <img src={team1} alt="team" />
                                    </div>
                                </div>
                                <h6>John Doe</h6>
                                <span className='slogan'>
                                    Some Text here
                                </span>
                            </div>
                        </Col>
                        <Col lg={3} md={6}>
                            <div className='teamMain'>
                                <div className='teamMain__frame smallFrame'>
                                    <div className='teamMain__img'>
                                        <img src={team2} alt="team" />
                                    </div>
                                </div>
                                <h6>John Doe</h6>
                                <span className='slogan'>
                                    Some Text here
                                </span>
                            </div>
                        </Col>
                        <Col lg={3} md={6}>
                            <div className='teamMain'>
                                <div className='teamMain__frame smallFrame'>
                                    <div className='teamMain__img'>
                                        <img src={team3} alt="team" />
                                    </div>
                                </div>
                                <h6>John Doe</h6>
                                <span className='slogan'>
                                    Some Text here
                                </span>
                            </div>
                        </Col>
                        <Col lg={3} md={6}>
                            <div className='teamMain'>
                                <div className='teamMain__frame'>
                                    <div className='teamMain__img'>
                                        <img src={team4} alt="team" />
                                    </div>
                                </div>
                                <h6>John Doe</h6>
                                <span className='slogan'>
                                    Some Text here
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Team;