import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Staking from "./pages/Staking";
import AnimatedCursor from "react-animated-cursor";


function App() {
    return (
      <>

      <AnimatedCursor
      innerSize={20}
      outerSize={15}
      color='293, 11, 111'
      outerAlpha={0.4}
      innerScale={0.7}
      outerScale={5}     
    />
       

   <BrowserRouter>


      <Routes>
        <Route path="/" element={<Home />} />
         
          <Route path="/staking" element={<Staking />} />     

       
      </Routes>
    </BrowserRouter>


    </>

    
  );
}

export default App;
