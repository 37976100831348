import React, {useEffect} from 'react';
import lottie from "lottie-web";
import { Col, Row, Container } from 'react-bootstrap';
import officegirl from '../assets/img/office-girl.png';
import officegirlGif from '../animation/Women-on-chhair.json';

const OfficeGirl = () => {

    useEffect(() => {
        lottie.loadAnimation({
          container: document.querySelector("#officegirlgif"),
          animationData: officegirlGif,
          renderer: "svg", 
          loop: true, 
        });
      }, []);

    return (
        <>
            <section className="officegirlBG">
                <Container>
                    <Row className='align-items-center'>
                        <Col lg={6} className="order-1 order-lg-0">
                            <div className='officegirlText'>
                                <span className='slogan text-white'>Lorem ipsum dolar sit amet</span>
                                <h2>Lorem ipsum dolar sit amet</h2>
                                <p>Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet Constructor  Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet Constructor </p>
                                <a href='#' className='theme-btn'>Get Started</a>
                            </div>
                        </Col>
                        <Col lg={6} className="order-0 order-lg-1">
                            <div className='officeGirlimg'>
                                <div id='officegirlgif' />
                            </div>    
                        </Col>


                    </Row>
                </Container>
            </section>
        </>
    )
}

export default OfficeGirl;