import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import team from '../assets/img/team/60.png';
import NFTData from '../data/Nft.json';
import secondNFTData from '../data/secondNft.json';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";


const CardTeam = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <FaChevronRight />,
        prevArrow: <FaChevronLeft />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };
    return (
        <>
            <section className='teamBG' id="card-team">
                <Container>
                    <Row>
                        <Col lg={6}>
                            <span className='slogan'>Lorem ipsum dolar sit amet</span>
                            <h2>Lorem ipsum dolar sit amet</h2>
                        </Col>
                        <Col lg={6}>
                            <p>Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet Constructor  Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet Constructor </p>
                        </Col>
                    </Row>
                    <div className='teamSlide'>

                        <Slider {...settings}>
                            {NFTData.map(data => 

                                    <div className='teamslide'>
                                        <img src={data.image} alt={data.alt} />
                                        
                                    </div>

                                )}
                          
                        </Slider>

                        <Slider {...settings}>
                            {secondNFTData.map(data => 

                                    <div className='teamslide'>
                                        <img src={data.image} alt={data.alt} />
                                        
                                    </div>

                                )}
                          
                        </Slider>
                        
                        <a href="#" className='theme-btn'>Get Started</a>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default CardTeam;