import React from "react";
import About from "../components/About";
import Banner from "../components/Banner";
import Collections from "../components/Collections";
import Counter from "../components/Counter";
import Features from "../components/Features";
import Header from "../components/Header";
import Laverse from "../components/Laverse";
import OfficeGirl from "../components/OfficeGirl";
import PeopleSitting from "../components/PeopleSitting";
import PlayCollections from "../components/PlayCollections";
import Roadmap from "../components/Roadmap";
import CardTeam from "../components/CardTeam";
import VirtualEvent from "../components/VirtualEvent";
import VirtualEventHouse from "../components/VirtualEventHouse";
import Team from "../components/Team";
import Partners from "../components/Partners";
import Footer from "../components/Footer";


const Home = () => {
  return (
    <div> 

  
      <Header />
      <Banner />
      <About />
      <Laverse />  
      <Collections />    
      <PlayCollections />
      <Counter />
      <OfficeGirl />
      <PeopleSitting />
      <Features />
      <CardTeam />
      <VirtualEvent />
      <VirtualEventHouse />
      <Roadmap />
      <Team />
      <Partners />
      <Footer />
    </div>
  );
}

export default Home;