import React, {useEffect} from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import lottie from "lottie-web";
import collectionObj from '../assets/img/collectionobj.png';
import Animation from '../animation/main-illustration.json';

const Collections = () => {
       useEffect(() => {
        lottie.loadAnimation({
          container: document.querySelector("#image"),
          animationData: Animation,
          renderer: "svg", 
          loop: true, 
          autoplay: true, 
        });
      }, []);
    return (
        <>

        
            <section className="collectionSky">
                <div className='collectionsBG'>
                <Container>
                        <Row className='align-items-center justify-content-center'>
                            <Col lg={6} className="order-1 order-lg-0 text-center">
                                <div className='collectionText'>
                                    <span className='slogan'>Lorem ipsum dolar sit amet</span> 
                                    <h2>Start Your own collection</h2> 
                                    <p>Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet Constructor  Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet Constructor </p>  
                                    
                                    
                                </div>   
                                
                            </Col>

                            <Col lg={6}>
                                 <div id='image' /> 
                            </Col>
                            
                        </Row>
                </Container>
                </div>
            </section>
        </>
    )
}

export default Collections;