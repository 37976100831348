import React, { useState, useEffect } from 'react';
import lottie from "lottie-web";
import { Container } from 'react-bootstrap';
import Laverseimg from '../assets/img/laverseBig.svg';
import Coinflying from '../assets/img/goldfly.png';
import goldBox from '../assets/img/gold-box.svg';
import LaverseModal from './LavarseModal.js'
import goldBoxGif from '../animation/Tresure-box.json';

const Laverse = () => {
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        lottie.loadAnimation({
          container: document.querySelector("#goldboxGif"),
          animationData: goldBoxGif,
          renderer: "svg", 
          loop: true, 
          autoplay: true, 
        });
      }, []);
    return (
        <>

            <section className="laverseBG" id="staking">
                <Container>
                    <div className='laverseMain'>
                        <div className='laverseMain__inner'>
                            <img src={Laverseimg} />
                            <a href='javascrip:void();' onClick={() => setModalShow(true)} className='theme-btn smallbtn'>Get Started</a>
                        </div>
                        <div className='themeObject coinfly'>  <img src={Coinflying} /> </div>
                        <div className='themeObject goldBoxx'>  <div id='goldboxGif' /> </div>
                    </div>
                </Container>
            </section>

            <LaverseModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

export default Laverse;