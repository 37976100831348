import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import goldImage from '../assets/img/banner-gold.png';
import goldBox from '../assets/img/gold-box.svg';
import Plus from '../assets/img/banner-plus.svg';
import Plus1 from '../assets/img/banner-plus2.svg';
import Particles from "react-tsparticles";

const Banner = () => {

    return (
        <div>  
            
            <section className="bannerMain" id="home">
           <div class="iframe-wrapper">
              <iframe src="https://player.vimeo.com/video/695155915?h=5554bdc985&muted=1&autoplay=1&loop=1&transparent=0&background=1&app_id=122963"></iframe>

            </div>
                <Container>

                    <Row >
                        <Col lg={9}>
                            <div className="bannerText">
                                <div className='themeObject goldBox'><img src={goldBox} alt="Gold" /></div>
                                <div className='themeObject bannerPlus'><img src={Plus} alt="Gold" /></div>
                                <div className='themeObject bannerPlus1'><img src={Plus1} alt="Gold" /></div>
                                <h1>Lorem Ipsum Dolor Sit Amet Constructor</h1>
                                <p>Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet Constructor</p>
                                <a href="#" className="theme-btn">Get Started</a>
                            </div>
                        </Col>

                    </Row>

                </Container>
                <div className='bannerImg'>
                    <img src={goldImage} alt="Gold" />
                </div>
            </section>
        </div>
    )
}

export default Banner;