import React from 'react';
import { Link } from "react-router-dom";
import { Navbar, Container, Nav, NavDropdown, Form, Button } from 'react-bootstrap';
import homeIcon from '../assets/img/home.svg'
import gamepadIcon from '../assets/img/gamepad.svg'
import cartIcon from '../assets/img/cart.svg'
import laptopIcon from '../assets/img/laptop.svg'
import logo from '../assets/img/logo.png'
import land from '../assets/img/land.png'
import event from '../assets/img/event.png'

const Header = () => {
    return (
        <>
            {/* Desktop Navbar */}
            <Navbar expand="xl" className="d-none d-xl-flex p-0" fixed="top">
                <Container fluid className='p-0'>


                    <div className='navbar__nav'>
                        <ul>
                            <li>
                                
                                    <Link to="/">
                                    <div className='navbar__icon'>
                                        <img src={homeIcon} alt="navIcon" />

                                    </div>

                                    Home</Link>
                                
                            </li>
                            <li>
                                <a href='#gamepad'>
                                    <div className='navbar__icon'>
                                        <img src={gamepadIcon} alt="navIcon" />
                                    </div>
                                    <span>
                                        Gamepad
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href='#card-team'>
                                    <div className='navbar__icon'>
                                        <img src={cartIcon} alt="navIcon" />
                                    </div>
                                    <span>
                                        Marketplace
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <Navbar.Brand href="#"><img src={logo} alt="logo" /></Navbar.Brand>
                    <div className='navbar__nav'>
                        <ul>
                            <li>
                                <a href='#event'>
                                    <div className='navbar__icon'>
                                        <img src={event} alt="navIcon" />
                                    </div>
                                    <span>
                                        Event Hosting
                                    </span>
                                </a>
                            </li>
                            <li>

                                <Link to="/staking">
                                    <div className='navbar__icon'>
                                        <img src={laptopIcon} alt="navIcon" />
                                    </div>
                                   Staking
                                   </Link>
                                
                            </li>
                            <li>
                                <a href='#land'>
                                    <div className='navbar__icon'>
                                     <img src={land} alt="navIcon" />
                                    </div>
                                    <span>
                                        Land
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>



                </Container>
            </Navbar>


            <Navbar expand="xl" className="d-xl-none mobileNavbar" variant='dark' fixed="top">
                <Container fluid>



                    <Navbar.Brand href="#"><img src={logo} alt="logo" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />

                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="me-auto my-2 my-lg-0"
                        >
                            <Nav.Link href="#action1">Home</Nav.Link>
                            <Nav.Link href="#action2">Gamepad</Nav.Link>
                            <Nav.Link href="#action2">Marketplace</Nav.Link>
                            <Nav.Link href="#action2"> Event Hosting</Nav.Link>
                            <Nav.Link href="#action2">Staking</Nav.Link>
                            <Nav.Link href="#action2"> Log in</Nav.Link>

                        </Nav>

                    </Navbar.Collapse>

                </Container>
            </Navbar>
        </>
    )
}
export default Header;