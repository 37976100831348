import React, {useEffect} from 'react';
import lottie from "lottie-web";
import { Col, Row, Container } from 'react-bootstrap';
import virtualhouse from '../assets/img/Map-new.png';
import virtualhouseGif from '../animation/NewMap.json';

const VirtualEventHouse = () => {
    useEffect(() => {
        lottie.loadAnimation({
          container: document.querySelector("#vehouse"),
          animationData: virtualhouseGif,
          renderer: "svg", 
          loop: true, 
        });
      }, []);
    return (
        <>
            <section className='virtualEventHouseBG' id="land">
                <Container>
                    <div className='vehouseInner'>
                        <div id='vehouse' />
                        <div class="featuresText"><span class="slogan">Lorem ipsum dolar sit amet</span><h2>Lorem ipsum dolar sit amet</h2><p>Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet Constructor  Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet Constructor </p></div>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default VirtualEventHouse;