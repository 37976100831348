import React, { useEffect } from 'react';
import lottie from "lottie-web";
import { Col, Row, Container } from 'react-bootstrap';
import aboutImg from '../assets/img/aboutimg.png';
import pluBig from '../assets/img/aboutPlus.svg';
import plugDouble from '../assets/img/aboutdoubleplus.svg';
import aboutImgGif from '../animation/about.json';

const About = () => {

    useEffect(() => {
        lottie.loadAnimation({
            container: document.querySelector("#aboutGif"),
            animationData: aboutImgGif,
            renderer: "svg",
            loop: true,
            autoplay: true,
        });
    }, []);

    return (
        <>

            <section className="aboutBG" id="about">
                <div className='dividerOpacity'></div>
                <Container>
                    <div className='aboutWrapBg'>
                        <Row className='align-items-center'>
                            <Col lg={7} className="order-1 order-lg-0">
                                <div className='aboutText'>
                                    <h3>Lorem Ipsum Dolor Sit Amet Constructor</h3>
                                    <p>Lorem Ipsum Dolor Sit Amet Constructor Lorem Ipsum Dolor Sit Amet Constructor</p>
                                    <a href='#' className='theme-btn smallbtn'>Get Started</a>
                                </div>
                            </Col>
                            <Col lg={5} className="order-0 order-lg-1">
                                <div className='aboutImg'>
                                    <div id='aboutGif'></div>
                                </div>
                            </Col>
                        </Row>
                        <div className='themeObject plusBiga'> <img src={pluBig} />  </div>
                        <div className='themeObject plusdoublea'> <img src={plugDouble} />  </div>
                    </div>
                </Container>
                <div className='dividerOpacityBottom'></div>
            </section>
        </>
    )
}

export default About;